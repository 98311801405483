import Vue from 'vue';

const notifySuccess = ({ title, text }) => {
    Vue.notify({
        title: title,
        text: text,
        type: 'success',
    });
}

const notifyError = ({ title, errors }) => {
    Vue.notify({
        title: title,
        text: Array.isArray(errors) ? errors.map(item => item.message).join(',\n') : errors,
        type: 'error',
    });
}

const notifyDefaultError = ({ error }) => {
    Vue.notify({
        title: `Unexpected error: ${error}`,
        text: 'Something went wrong',
        type: 'error',
    });
}

export { notifyError, notifySuccess, notifyDefaultError }