import { QrItemActionTypes, QrItemMutationTypes } from './types';
import { notifySuccess } from '../../helpers/notifications';

export const actions = {
    async [QrItemActionTypes.GetQrItem]({ commit }, { params }) {
        return new Promise((resolve, reject) => {
            this.$api.getQRItems.get({
                params: { ...params },
            }).then(({data}) => {
                if (!data.error_message) {
                    commit(`${QrItemMutationTypes.SetQrItemData}`, { data: data.data.data })
                    notifySuccess({ title: data.message })
                    resolve({data})
                } else {
                    // notifyError({ title: data.error_message, errors: data.errors })
                    resolve(data?.error_message)
                }
            })
            .catch((error) => {
                // notifyDefaultError({ error })
                reject(error)
            })
        })
    },
    async [QrItemActionTypes.CountQrItem](context, { data }) {
        return new Promise((resolve, reject) => {
            this.$api.countQRItem.post({
                body: { ...data },
            }).then(({data}) => {
                if (!data.error_message) {
                    notifySuccess({ title: data.message })

                    resolve({data})
                } else {
                    // notifyError({ title: data.error_message, errors: data.errors })
                    resolve(data?.error_message)
                }
            })
            .catch((error) => {
                // notifyDefaultError({ error })
                reject(error)
            })
        })
    },
    async [QrItemActionTypes.GetUserQrCount]() {
        return new Promise((resolve, reject) => {
            this.$api.getUserQrCount.get().then(({data}) => {
                if (!data.error_message) {
                    notifySuccess({ title: data.message })
                    resolve({data})
                } else {
                    // notifyError({ title: data.error_message, errors: data.errors })
                    resolve(data?.error_message)
                }
            })
            .catch((error) => {
                // notifyDefaultError({ error })
                reject(error)
            })
        })
    },
}