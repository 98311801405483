const registration = [{
    path: '/registration',
    name: 'registration',
    meta: {
        auth: false,
    },
    component: () => 
        import(/* webpackChunkName: "Registration" */ '@/views/Registration')
}]

export {registration}