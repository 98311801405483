import { RegistrationActionTypes } from './types';
import { notifySuccess} from '../../helpers/notifications';

export const actions = {
    async [RegistrationActionTypes.Register](context, { data }) {
        return new Promise((resolve, reject) => {
            this.$api.signup.post({
                body: { ...data },
            }).then(({data}) => {
                if (data && !data.error_message) {
                    notifySuccess({ title: data.message })

                    resolve({data})
                } else {
                    // notifyError({ title: data.error_message, errors: data.errors })
                    resolve(data?.error_message)
                }
            })
            .catch((error) => {
                // notifyDefaultError({ error })
                reject(error)
            })
        })
    },
}