import { AuthorizationActionTypes } from './types';
import { setUserAuthData, setUserLoginStatus } from '../../helpers/authorization';
import { notifySuccess} from '../../helpers/notifications';

export const actions = {
    async [AuthorizationActionTypes.Login](context, { email, password }) {
        return new Promise((resolve, reject) => {
            this.$api.login.post({
                body: { email, password },
            }).then((data) => {         
                
                if (data && data.data && !data.error_message) {
                    setUserAuthData({ data: data.data })
                    setUserLoginStatus()

                    // notifySuccess({ title: data.message })

                    // window.open('/', '_self')

                    resolve({data})
                } else if (data && data.error_message) {
                    // notifyError({ title: data.error_message, errors: data.errors })
                    resolve(data?.error_message)
                } else {
                    // notifyError({ title: 'Ошибка', errors: 'Что-то пошло не так' })
                    resolve(data)
                }
            })
            .catch((error) => {
                // notifyDefaultError({ error })
                reject(error)
            })
        })
    },
    async [AuthorizationActionTypes.FindAndLogin](context, { params }) {
        return new Promise((resolve, reject) => {
            this.$api.findAndLogin.get({
                params: { ...params },
            }).then(({data}) => {
                if (!data.error_message) {
                    notifySuccess({ title: data.message })

                    resolve({data})
                } else {
                    // notifyError({ title: data.error_message, errors: data.errors })
                    resolve(data?.error_message)
                }
            })
            .catch((error) => {
                // notifyDefaultError({ error })
                reject(error)
            })
        })
    },
    async [AuthorizationActionTypes.Logout]() {
        return new Promise((resolve, reject) => {
            this.$api.logout.post()
                .then(response => {
                    if (!response.data.error_message) {
                        notifySuccess({ title: response.data.message })
                    } else {
                        // notifyError({ title: response.data.error_message, errors: response.data.errors })  
                    }
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
            })
    },
}