import axios from 'axios';


const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
});

instance.defaults.headers.common['Content-Type'] = 'application/json'
instance.defaults.withCredentials = true

export default instance
