import Vue from 'vue';
import Router from 'vue-router';
import { getUserLoginStatus } from '../helpers/authorization';

import { home } from './home';
import { login } from './login';
import { registration } from './registration';
import { count } from './count';
import { account } from './account';

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: '/',
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [
        ...home,
        ...login,
        ...registration,
        ...count,
        ...account,
    ]
})

router.beforeEach((to, from, next) => {
    const isLoggedIn = getUserLoginStatus()    
    if (to.meta.auth && !isLoggedIn) {
        return next({
            name: 'home',
        })
    } else {    
        return next();
    }
})

export default router