const AuthorizationNameSpace = 'authorization';

const AuthorizationActionTypes = Object.freeze({
    Login: 'Login',
    FindAndLogin: 'FindAndLogin',
    Logout: 'Logout',
    PingAuth: 'PingAuth',
});

const AuthorizationMutationTypes = Object.freeze({});

const AuthorizationGetterTypes = Object.freeze({});

export {
    AuthorizationNameSpace,
    AuthorizationActionTypes,
    AuthorizationMutationTypes,
    AuthorizationGetterTypes,
};