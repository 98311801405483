const home = [{
    path: '/',
    name: 'home',
    meta: {
        auth: false,
    },
    component: () => {
        let loggedIn = JSON.parse(localStorage.getItem('loggedIn'))
        let showHome = JSON.parse(localStorage.getItem('showHome'))
        if (loggedIn && !showHome) {
            return import(/* webpackChunkName: "Home" */ '@/views/Account')
        } else {
            return import(/* webpackChunkName: "Account" */ '@/views/Home')

        }
        
    }
        
}]

export {home}