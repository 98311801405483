import { NumberItemActionTypes, NumberItemMutationTypes } from './types';
import { notifySuccess } from '../../helpers/notifications';

export const actions = {
    async [NumberItemActionTypes.GetNumberItem]({ commit }, { params }) {
        return new Promise((resolve, reject) => {
            this.$api.getNumberItems.get({
                params: { ...params },
            }).then(({data}) => {
                if (!data.error_message) {
                    commit(`${NumberItemMutationTypes.SetNumberItemData}`, { data: data.data.data })
                    notifySuccess({ title: data.message })
                    resolve({data})
                } else {
                    // notifyError({ title: data.error_message, errors: data.errors })
                    resolve(data?.error_message)
                }
            })
            .catch((error) => {
                // notifyDefaultError({ error })
                reject(error)
            })
        })
    },
    async [NumberItemActionTypes.CountNumberItem](context, { data }) {
        return new Promise((resolve, reject) => {
            this.$api.countNumberItem.post({
                body: { ...data },
            }).then(({data}) => {
                if (!data.error_message) {
                    notifySuccess({ title: data.message })

                    resolve({data})
                } else {
                    // notifyError({ title: data.error_message, errors: data.errors })
                    resolve(data?.error_message)
                }
            })
            .catch((error) => {
                // notifyDefaultError({ error })
                reject(error)
            })
        })
    },
    async [NumberItemActionTypes.GetUserNumberCount]() {
        return new Promise((resolve, reject) => {
            this.$api.getUserNumberCount.get().then(({data}) => {
                if (!data.error_message) {
                    // notifySuccess({ title: data.message })
                    resolve({data})
                } else {
                    // notifyError({ title: data.error_message, errors: data.errors })
                    resolve(data?.error_message)
                }
            })
            .catch((error) => {
                // notifyDefaultError({ error })
                reject(error)
            })
        })
    },
}