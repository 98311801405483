import Vue from 'vue';
import Vuex from 'vuex';
import { state } from './state';
import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';

//Modules
import authorization from "./authorization";
import registration from "./registration";
import qritem from "./qr-item";
import numberitem from "./number-item"

import api from '../plugins/index';
Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [api],
  state,
  getters,
  mutations,
  actions,
  modules: {
    authorization,
    registration,
    qritem,
    numberitem,
  }
})
