<template lang="pug">
  #app
    router-view
    notifications(position="bottom right", :duration='10000')
    v-dialog
</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="scss">
// @import './assets/styles/main';
  @import './assets/fonts/pancettaPro/stylesheet.css';


  body {
    background-color: #E1EDF8;
    font-family: 'Pancetta Pro';

    .lds-dual-ring {
      display: inline-block;
    }
    .lds-dual-ring:after {
        content: " ";
        display: block;
        width: 61px;
        height: 61px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid #E6007E;
        border-color: #E6007E transparent #E6007E transparent;
        animation: lds-dual-ring 1.2s linear infinite;
    }
    @keyframes lds-dual-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    .button-pink{
      background: #E6007E;
      border-radius: 30px;
      color: #FFF;
      font-weight: 900;
      font-size: 35px;
      cursor: pointer;
      text-align: center;
      height: 55px;
      padding: 5px 60px 5px 65px !important;
      text-transform: uppercase;
      &:hover{
        opacity: 0.8;
      }
    }

    .input-pink{
      background: #F5F5F5;
      border: 4px solid #E4007A;
      border-radius: 30px;
      height: 55px;
      padding: 5px 20px 0px 20px;
      text-transform: uppercase;
    }

    .button-white{
      background: #FFF;
      border-radius: 30px;
      color: #E4007A;
      border: 1px solid #E4007A;
      font-weight: 900;
      font-size: 28px;
      font-weight: bold;
      cursor: pointer;
      text-align: center;
      height: 50px;
      padding: 6px 80px 5px 80px;
      text-transform: uppercase;
      &:hover{
        opacity: 0.8;
      }
      @media (max-width:500px){
        font-size: 16px;
        padding: 4px 50px 1px 50px;
        height: 30px;
        margin-top: 40px;
      }
    }
  }
  
</style>
