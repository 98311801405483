const account = [{
    path: '/account',
    name: 'account',
    meta: {
        auth: true,
    },
    component: () => 
        import(/* webpackChunkName: "Account" */ '@/views/Account')
}]

export {account}