//VueI18n
import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const messages = {
    ru: {
        views: {
            home: {
                header: 'Текст'
            }
        },
        ui: {
            modals: {
                actions: {
                    confirm: 'да',
                    decline: 'нет'
                }
            }
        }     
    },
    en: {}
}

export const i18n = new VueI18n({
    locale: 'ru',
    messages,
})