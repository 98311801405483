const login = [{
    path: '/login',
    name: 'login',
    meta: {
        auth: false,
    },
    component: () => 
        import(/* webpackChunkName: "Login" */ '@/views/Login')
}]

export {login}