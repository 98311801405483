const QrItemNameSpace = 'qritem';

const QrItemActionTypes = Object.freeze({
    GetQrItem: 'GetQrItem',
    CountQrItem: 'CountQrItem',
    GetUserQrCount: 'GetUserQrCount',
});

const QrItemMutationTypes = Object.freeze({
    SetQrItemData: 'SetQrItemData',
});

const QrItemGetterTypes = Object.freeze({
    GetQrItemData: 'GetQrItemData',
});

export {
    QrItemNameSpace,
    QrItemActionTypes,
    QrItemMutationTypes,
    QrItemGetterTypes,
};