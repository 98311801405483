/* eslint-disable no-unused-vars */
import Vue from 'vue';
const $vm = Vue.prototype;

class BaseService {
    constructor(resource) {
        if (!resource) throw new Error('Path not provided');
        // this.baseUrl = 'api/v1/';
        // this.path = this.baseUrl + resource;
        this.path = resource; 
    }

    errorHandler(data) {
        return data;
    }

    url({ id }) {
        const path = this.path;
        return id ? path.concat('/', id) : path;
    }
}

class ReadOnlyService extends BaseService {
    constructor(resource) {
        super(resource)
    }

    async get({ 
        id = '', 
        params = {} 
    } = {}) {
        try {
            const url = this.url({ id });
            const { data } = await $vm.$http(url, {params: {...params}});
            return { data }; 
        } catch (error) {
            this.errorHandler(error.response.data);
        }
    }
}

class ModelService extends BaseService {
    constructor(resource) {
        super(resource)
    }
    
    async post({ 
        method = 'POST', 
        headers, 
        id = '', 
        body = {} 
    } = {}) {
        try {
            const url = this.url({ id });
            const { data } = await $vm.$http(url, {
                method: method,
                data: body,
                headers: headers,
            })

            return { data };
        } catch (error) {
            this.errorHandler(error.response.data);
        }
    }
}

class PatchService extends BaseService {
    constructor(resource) {
        super(resource)
    }
    
    async patch({ 
        method = 'PATCH', 
        headers, 
        id = '', 
        body = {} 
    } = {}) {
        try {
            const url = this.url({ id });
            
            const { data } = await $vm.$http(url, {
                method: method,
                data: body,
                headers: {...headers},
            })

            return { data };
        } catch (error) {
            this.errorHandler(error.response.data);
        }
    }
}

class DeleteService extends BaseService {
    constructor(resource) {
        super(resource)
    }
    
    async delete({ 
        method = 'DELETE', 
        id = '', 
        body = {}
    } = {}) {
        try {
            const url = this.url({ id });
            
            const { data } = await $vm.$http(url, {
                method: method,
                data: body,
            })

            return { data };
        } catch (error) {
            this.errorHandler(error.response.data);
        }
    }
}

// Authorization

class Login extends ModelService {
    constructor() {
        super('users/login')
    }
}
class FindAndLogin extends ReadOnlyService {
    constructor() {
        super('users/find-and-login')
    }
}
class Logout extends ModelService {
    constructor() {
        super('users/logout')
    }
}

// Registration

class Signup extends ModelService {
    constructor() {
        super('users/signup')
    }
}

// QR Item
class GetQRItems extends ReadOnlyService {
    constructor() {
        super('qr/qr-item/collection')
    }
}

class CountQRItem extends ModelService {
    constructor() {
        super('qr/qr-item/count')
    }
}

class GetUserQrCount extends ReadOnlyService {
    constructor() {
        super('qr/qr-item/user-counter')
    }
}

// Number Item
class GetNumberItems extends ReadOnlyService {
    constructor() {
        super('number/number-item/collection')
    }
}

class CountNumberItem extends ModelService {
    constructor() {
        super('number/number-item/count')
    }
}

class GetUserNumberCount extends ReadOnlyService {
    constructor() {
        super('number/number-item/user-counter')
    }
}

export const $api = {
    login: new Login(),
    findAndLogin: new FindAndLogin(),
    logout: new Logout(),
    signup: new Signup(),

    getQRItems: new GetQRItems(),
    countQRItem: new CountQRItem(),
    getUserQrCount: new GetUserQrCount(),

    getNumberItems: new GetNumberItems(),
    countNumberItem: new CountNumberItem(),
    getUserNumberCount: new GetUserNumberCount(),
}