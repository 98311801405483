const NumberItemNameSpace = 'numberitem';

const NumberItemActionTypes = Object.freeze({
    GetNumberItem: 'GetNumberItem',
    CountNumberItem: 'CountNumberItem',
    GetUserNumberCount: 'GetUserNumberCount',
});

const NumberItemMutationTypes = Object.freeze({
    SetNumberItemData: 'SetNumberItemData',
});

const NumberItemGetterTypes = Object.freeze({
    GetNumberItemData: 'GetNumberItemData',
});

export {
    NumberItemNameSpace,
    NumberItemActionTypes,
    NumberItemMutationTypes,
    NumberItemGetterTypes,
};