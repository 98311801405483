const RootNameSpace = 'root';

const RootActionTypes = Object.freeze({
    SetDeleteFilterStatus: 'SetDeleteFilterStatus',
});

const RootMutationTypes = Object.freeze({
    SetDeleteFilterStatus: 'SetDeleteFilterStatus',
});

const RootGetterTypes = Object.freeze({
    GetDeleteFilterStatus: 'GetDeleteFilterStatus',
});

export {
    RootNameSpace,
    RootActionTypes,
    RootMutationTypes,
    RootGetterTypes,
};