import axios from 'axios';
import { 
    clearUserLoginStatus,  
    setUserLoginStatus,
} from '../helpers/authorization';

import { notifyError } from '../helpers/notifications';

const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
});

instance.defaults.headers.common['Content-Type'] = 'application/json'
instance.defaults.withCredentials = true

instance.interceptors.response.use(
    (response) => {
        return response
    },
    async (error) => {
        const error_response = error.response.data;
        try {
            if (error.response.status === 401) {         
                clearUserLoginStatus()
  
                await axios({
                    method: 'GET',
                    baseURL: process.env.VUE_APP_BASE_URL,
                    url: 'auth/get-refresh-token-pair',
                    withCredentials: true,
                })

                setUserLoginStatus()

                return instance(error.config)
            } else if (error.response.status === 500) {
                notifyError({ title: error_response.error_message, errors: error_response.errors })
                clearUserLoginStatus()
            } else {
                notifyError({ title: error_response.error_message, errors: error_response.errors })
                clearUserLoginStatus()
                return Promise.reject(error)
            }
        } catch (error) {
            notifyError({ title: error_response.error_message, errors: error_response.errors })
            clearUserLoginStatus()
            if (window.location.pathname !== '/count') {
                window.open('/', '_self')
            }
        }
    }
)

export default instance
