const RegistrationNameSpace = 'registration';

const RegistrationActionTypes = Object.freeze({
    Register: 'Register',
});

const RegistrationMutationTypes = Object.freeze({
    SetRegistrationData: 'SetRegistrationData',
});

const RegistrationGetterTypes = Object.freeze({
    GetRegistrationData: 'GetRegistrationData',
});

export {
    RegistrationNameSpace,
    RegistrationActionTypes,
    RegistrationMutationTypes,
    RegistrationGetterTypes,
};