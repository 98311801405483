const count = [{
    path: '/count',
    name: 'count',
    meta: {
        auth: false,
    },
    component: () => 
        import(/* webpackChunkName: "Count" */ '@/views/Count')
}]

export {count}